import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../components/Breadcrumb.css'

import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";

import BgImage1 from "../img/news-bg.png";

export const NewsPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  image,
  date,
  pageContext
}) => {
  const PostContent = contentComponent || Content;
  const { breadcrumb: {crumbs}} = pageContext;

  return (
    <div>
      <Header>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel={title}  />
        <ImageBig 
          imageInfo={{
            image: image,
            alt: `featured image thumbnail for post ${title}`,
          }}
          style={{
            height: "550px"
          }}
          imageStyle={{
            borderRadius: "10px",
            objectFit: "cover",
            objectPosition: "0% 50%",
            maxHeight: "550px",
          }}
          ></ImageBig>
        <MidashiBg>
          <Midashi>{title}</Midashi>
          <p>{date}</p>
          {tags.map(tag => (
            <TagButton>{tag}</TagButton>
          ))}
        </MidashiBg>
      </Header>
      <Body>
        <PostContent content={content} />
        <br />
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel={title}  />
      </Body>
    </div>
  );
};

const Header = styled.div`
  margin: 0 5vw 0 5vw;
  text-align: center;
`;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  text-align: left;
`;

const ImageBig = styled(PreviewCompatibleImage)`
  width: 100%;
  height: 550px;
  border-radius: 10px;
  object-fit: cover;
`;

const MidashiBg = styled.div`
  width: 100%;
  height: 15em;
  background-image: url(${BgImage1});
  padding: 5em 15vw 0 15vw;
  margin: 2em 0 5em 0;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 24pt;
  margin: 0 0 1em 0;
`;

const TagButton = styled.button`
  background: #666666;
  width: 7em;
  height: 2em;
  margin: 1em 0 2em 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  color: #ffffff;
  
  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    border-radius: 10px;
    color: #666666;
    pointer: cursor;
  }
`;



NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const NewsPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <NewsPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage}
        date={post.frontmatter.date}
        pageContext={pageContext}
      />
    </Layout>
  );
};

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        tags
      }
    }
  }
`;
